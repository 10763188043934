<template>
  <div style="background: whitesmoke" class="master">
    <div class="header">
      <div class="left-part">
        <img
          :src="image"
          alt="">
      </div>
      <div class="right-part">
        <span>{{title}}</span>
      </div>
    </div>

    <div class="body">
      <property-view v-for="(prop, index) in childProperties"
                     :key="index"
                     :images="[{url: prop.cover_photo}]"
                     :property="prop"></property-view>
    </div>

    <div style="height: 1px"></div>
  </div>
</template>

<script>
  import childProperties from './../../child-properties-details'
  import {Mail} from 'vue-feather-icon'
  import propertyView from './property-view'

  export default {
    name: "master-view",
    data: () => {
      return {
        childProperties: childProperties
      }
    },
    computed: {
      image() {
        return process.env.VUE_APP_MASTER_IMAGE
      },
      title() {
        return process.env.VUE_APP_MASTER_TITLE
      }
    },
    components: {
      mail: Mail.default,
      propertyView
    },
    mounted() {

    },
    methods: {
      goToProperty(property) {
        window.location.href = property.website;
      }
    }
  }
</script>

<style scoped lang="scss">

  .master {
    font-family: Poppins, sans-serif;
  }

  .header {
    height: 100px;
    width: 100%;
    display: grid;
    background: white;
    grid-template-columns: 120px 1fr;
    box-shadow: 0px 3px 14px 0px #dadada;

    > *:not(.master-property-name) {
      cursor: pointer;
    }

    .left-part {
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #f0f0f0;

      img {
        width: 100px;
        height: 90px;
      }
    }

    .right-part {
      display: flex;
      text-align: center;
      justify-content: center;

      span {
        display: flex;
        align-items: center;
        font-size: 22px;
      }
    }

  }
</style>
